::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: grey;
}

::-webkit-scrollbar-track {
  background: transparent;
}
