@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

body {
  font-family: 'Roboto', cursive;
}

option {
  color: black;
}

.starry-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  z-index: -1;
}

.star-white {
  position: absolute;
  background: #e9a6a6;
  width: 3px;
  height: 3px;
  animation: blink 1s linear infinite, move 20s linear infinite;
  z-index: 9999;
  border-radius: 50%;
}

.star-black {
  position: absolute;
  background: #0284c7;
  width: 3px;
  height: 3px;
  animation: blink 1s linear infinite, move 20s linear infinite;
  z-index: 9999;
  border-radius: 50%;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100vh);
  }
}
